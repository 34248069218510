import React, { lazy } from 'react';
import { useHistory } from 'react-router-dom';
import ThirdPartyAppLayout from 'layouts/ThirdPartyAppLayout';
import { useAppSelector } from 'hooks/redux';
import { selectAccessToken } from 'modules/Auth/selectors';
import { selectCurrentVenueId } from 'modules/Partner/selectors';
import { SETTINGS_GIFTCARD_PAGE } from 'constants/routes';

const GiftcardDashBoard = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "umai-giftcard-dashboard" */ '@umai/giftcard-dashboard'
    )
);

function Giftcards() {
  const userAccessToken = useAppSelector(selectAccessToken);
  const currentVenueId = useAppSelector(selectCurrentVenueId);
  const history = useHistory();

  const venueConfig = {
    isUmai360: true,
    currentVenueId,
    userAccessToken,
  };

  return (
    <ThirdPartyAppLayout
      id="giftcard-dashboard-root"
      className="umaiGiftcardDashboardParent"
      hideMobileFilters
    >
      <GiftcardDashBoard
        config={venueConfig}
        openGiftcardSettings={() => history.push(SETTINGS_GIFTCARD_PAGE)}
      />
    </ThirdPartyAppLayout>
  );
}

export default Giftcards;
