import React, { lazy } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { ROUTES } from 'constants/routes';
import { DATE_FORMAT } from 'constants/time-and-date';
import { getCurrentDateAndTime } from 'utils/date-and-time';
import { selectFooterDate } from 'modules/Footer/selectors';
import { changeDate, getCalendarData } from 'modules/Footer/actions';
import ThirdPartyAppLayout from 'layouts/ThirdPartyAppLayout';

const CalendarPage = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "umai-calendar" */ '@umai/calendar')
);

function Calendar() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const footerDate = useAppSelector(selectFooterDate);

  const redirectToReservation = (date: any) => {
    const momentDate = moment(date, DATE_FORMAT);
    if (date && momentDate?.isValid()) {
      const isPast = momentDate.isBefore(getCurrentDateAndTime(), 'day');
      const pathname = isPast ? ROUTES.FINISHED_RESERVATIONS : ROUTES.RESERVATIONS;

      // redirect to finish or current list with the existing search params
      history.push({ ...location, pathname });

      // changing footer date to calendar date
      dispatch(changeDate(momentDate));

      // When date in query params is different than date in footer,
      // we need to make sure that footer calendar data is up to date as well
      if (footerDate.format(DATE_FORMAT) !== momentDate.format(DATE_FORMAT)) {
        dispatch(getCalendarData(momentDate));
      }
    }
  };

  return (
    <ThirdPartyAppLayout id="calendar-root" className="reservationCalendarAppParent">
      <CalendarPage redirectToReservation={redirectToReservation} />
    </ThirdPartyAppLayout>
  );
}

export default Calendar;
