import React, { lazy } from 'react';
import ThirdPartyAppLayout from 'layouts/ThirdPartyAppLayout';

const TrafficAnalytics = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "umai-traffic-analytics" */ '@umai/traffic-analytics'
    )
);

function Analytics() {
  return (
    <ThirdPartyAppLayout id="analytics-wrapper" className="umaiAnalyticsParent">
      <TrafficAnalytics />
    </ThirdPartyAppLayout>
  );
}

export default Analytics;
