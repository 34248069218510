export const OPERATIONAL_PERMISSIONS = {
  RESERVATION_OPERATIONS: 'reservation_operations',
  QUEUE_OPERATIONS: 'queue_operations',
  TAKEAWAY_AND_DELIVERY_OPERATIONS: 'takeaway_and_delivery_operations',
  GIFT_CARD_OPERATIONS: 'gift_card_operations',
  VIEW_FEEDBACK: 'view_feedback',
  VIEW_ANALYTICS: 'view_analytics',
  LOYALTY_TRANSACTIONS: 'loyalty_transactions',
  LOYALTY_ADDITIONS_DEDUCTIONS: 'loyalty_additions_and_deductions',
  LOYALTY_MANAGEMENT_REPORTING: 'loyalty_management_reporting',
};

// We don't have queue permission here because a user can not have access to queue without having reservation operations permission
export const GUEST_LIST_OPERATIONAL_PERMISSIONS = [
  OPERATIONAL_PERMISSIONS.RESERVATION_OPERATIONS,
  OPERATIONAL_PERMISSIONS.LOYALTY_TRANSACTIONS,
  OPERATIONAL_PERMISSIONS.LOYALTY_ADDITIONS_DEDUCTIONS,
  OPERATIONAL_PERMISSIONS.LOYALTY_MANAGEMENT_REPORTING,
];

export const ALL_PERMISSIONS = Object.values(OPERATIONAL_PERMISSIONS);
