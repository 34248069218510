import React, { lazy, useContext } from 'react';
import classnames from 'classnames';
import { isMobileDevice } from 'utils/devices';
import { AppContext } from 'contexts/app';
import ThirdPartyAppLayout from 'layouts/ThirdPartyAppLayout';

const SettingsPage = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "umai-settings" */ '@umai/settings')
);

function Settings() {
  const { isVenueSettings, handleSettingsRouteChange } = useContext(AppContext);

  return (
    <ThirdPartyAppLayout
      id="settings-wrapper"
      className={classnames('umaiSettingsParent', { 'mobile-device': isMobileDevice })}
      hasNoFooter={isVenueSettings}
      hideMobileFilters
    >
      <SettingsPage onRouteChange={handleSettingsRouteChange} />
    </ThirdPartyAppLayout>
  );
}

export default Settings;
