import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  className?: string;
  color?: string;
  width?: string | number;
  height?: string | number;
}

const CopyIcon = ({ width = 16, height = 16, onClick, className, color, ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    onClick={onClick}
    viewBox="0 0 20 20"
    fill="none"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fill={color}
      stroke={color}
      strokeWidth={0.18}
      d="M12.42 6.66H2.298C1.41 6.66.7 7.37.7 8.257v10.144C.7 19.267 1.41 20 2.298 20h10.145c.865 0 1.598-.71 1.598-1.598V8.258a1.625 1.625 0 0 0-1.62-1.599m.267 11.72a.246.246 0 0 1-.244.244H2.298a.246.246 0 0 1-.244-.244V8.258c0-.134.111-.245.244-.245h10.145c.133 0 .244.111.244.245z"
    />
    <path
      fill={color}
      stroke={color}
      strokeWidth={0.18}
      d="M17.748 0H7.626c-.888 0-1.599.71-1.599 1.598v3.73H7.36v-3.73c0-.133.111-.244.244-.244h10.145c.133 0 .244.111.244.244v10.145a.246.246 0 0 1-.244.244H15.35v1.332h2.398c.865 0 1.598-.71 1.598-1.599V1.598C19.346.71 18.636 0 17.748 0"
    />
  </svg>
);
export default CopyIcon;
